.commonStyledButton {
	border: #6418c3;
	border-radius: 0.625rem;
	padding: 0.5rem 0.625rem;
	min-height: 2.6875rem;

	/* font-size: 1rem; */
	cursor: pointer;
	color: #fff;
	min-width: 6.25rem;
	box-shadow: 0px 4px 4px #0000000a;
}

.primaryBoldButton {
	background: #6418c3;
}

.disabledButton {
	background: none;
	color: #545353;
	cursor: not-allowed;
}

.successButton {
	border: green;
	background: #13924e;
}
.smallSuccessButton {
	min-height: 1.25rem;
	border: green;
	background: #13924e;
	padding: 0.3125rem;
	min-width: 4.375rem;
	border-radius: 0.3125rem;
}
.successButton:hover,
.smallSuccessButton:hover {
	background: #29b86c;
}

.smallPrimaryBoldButton {
	min-height: 1.25rem;
	background: #6418c3;
	padding: 0.3125rem;
	min-width: 4.375rem;
	border-radius: 0.3125rem;
}

.primaryBoldButton:hover,
.smallPrimaryBoldButton:hover {
	background: #5811af;
}

.primaryDarkBoldButton {
	background: #37295e;
	border: #37295e;
}
.primaryDarkBoldButton:hover {
	background: #654caa;
	border: #654caa;
}

.secondaryBoldButton {
	background: #a157ff;
}
.secondaryBoldButton:hover {
	background: #8a45e4;
}

.outlinedBoldButton {
	background: #fff;
	color: #000;
	border: 1px solid #a5a5a5;
	color: #626262;
}
.outlinedBoldButton:hover {
	background: #a157ff;
	color: #fff;
}

.blackButton {
	box-shadow: none;
	color: #000;
}

.blackButton:hover {
	text-decoration: underline;
}

.greyButton {
	background: #575757;
}

.greyButton:hover {
	background: #7b7979;
}

.gradientButton {
	background: transparent linear-gradient(180deg, #6418c3 0%, #d188ff 100%) 0%
		0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #a8a8a821;
	border-radius: 1.625rem;
	padding: 0.625rem 1.875rem;
	margin-top: 1.25rem;
}

.gradientButton:hover {
	opacity: 0.9;
}
.linkButton {
	color: #6418c3;
	background-color: none;
	text-decoration: underline;
	box-shadow: none;
}

.linkButton:hover {
	color: #5810b1;
	background-color: none;
	text-decoration: underline;
	box-shadow: none;
}

.redButton {
	background: #ea0c48;
}
.redButton:hover {
	background-color: #c7073a;
}
.nonoutlinedButton {
	/* font-size: 1rem; */
	cursor: pointer;
	color: #464646;
	text-decoration: underline;
	transition: ease-in-out all 0.2s;
}

.nonoutlinedButton:hover {
	opacity: 0.7;
}

.gradientBTN {
	border: #6418c3;
	border-radius: 0.625rem;
	padding: 0.5rem 0.625rem;
	min-height: 2.6875rem;

	/* font-size: 1rem; */
	cursor: pointer;
	color: #fff;
	min-width: 6.25rem;
	box-shadow: 0px 4px 4px #0000000a;

	background: transparent linear-gradient(180deg, #6418c3 0%, #d188ff 100%) 0%
		0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #a8a8a821;
	border-radius: 1.625rem;
	padding: 0.625rem 1.875rem;
	margin-top: 1.25rem;
}

.smallPrimaryBoldButton:disabled,
.smallPrimaryBoldButton[disabled],
.nonoutlinedButton:disabled,
.nonoutlinedButton[disabled],
.primaryBoldButton:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}
