.notch-container {
	position: relative;
	overflow: hidden;
	height: 100vh;
}

.notch-container:before {
	/* position: absolute;
	padding: 0.8em;
	box-shadow: 0 0 6px #7e7e7e29;

	content: '';
	top: 37px;
	right: 2px;
	margin: -0.8em;
	border-radius: 50%;
	z-index: 1500; */
}

.pro-sidebar-layout {
	height: 100vh !important;
}

.collapseButtonContainerStyle {
	position: fixed;
	top: 1.375rem;
	z-index: 1500;
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	text-align: center;
	/* box-shadow: rgb(187 187 187 / 16%) 0px 3px 6px; */
	box-shadow: 0 0 6px #7e7e7e29;
	background: #ffffff;
	cursor: pointer;
	transition: ease-in-out all 0.3s;
}

.heading {
	padding: 1.375rem 0.9375rem;
	text-align: center;
	/*  */
	font-size: 1.75rem;
	color: #000;
}
