.test {
	padding-top: 3rem;
}

.publish__on {
	font-family: 'Cairo-Bold';
	color: #000000;
	text-transform: capitalize;
}
.TalkybotConnectionRealEstateCard {
	margin-top: 1rem;
	padding: 1rem;
	background-color: #fff;
	margin-right: 10px;
}
.Sec_center {
	margin-top: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Sec_Title {
	margin-top: 1rem;
}
.connectImage {
	width: 60px;
}

.providerName {
	font-family: 'Cairo-Bold';
	font-size: 25px;
	text-align: center;
	margin-top: 1rem;
	text-transform: uppercase;
}

.RealEstateCard_Icon {
	padding-right: 0.5rem;
}
.RealEstateText {
	font: normal normal bold 31px/47px Cairo;
	color: #000000;
	text-transform: capitalize;
}
.RealEstateCard_Collect_Leads {
	text-align: center;
	letter-spacing: 0px;
	color: #626262;
}
.RealState_btn_Sec {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}
.RealState_btn {
	display: flex;
	cursor: pointer;
	font-family: 'Cairo-Semi-Bold';
	padding: 0.5rem 2.5rem;
	background: #6919ba 0% 0% no-repeat padding-box;
	border: 1px solid #d5d5d5;
	width: 200px;
	font-size: 20px;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}
.RealState_Text {
	padding-left: 0.3rem;
	letter-spacing: 0px;
	text-transform: capitalize;
	opacity: 1;
}

.btn_Sec {
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Cairo-Semi-Bold';
	margin-top: 1rem;
}
.btn_connect {
	display: flex;
	font-family: 'Cairo-Semi-Bold';
	justify-content: center;
	align-items: center;
	padding: 0.6rem 1.5rem;
	cursor: pointer;
}
.btnIcon {
	padding-left: 0.3rem;
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #37295e;
	text-transform: capitalize;
	opacity: 1;
}
.btn_Text {
	fill: #6919ba;
	font: normal normal bold 16px/25px Poppins;
	padding-left: 0.2rem;
}

.linkButton {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	border: #6418c3;
	border-radius: 10px;
	padding: 8px 10px;
	min-height: 43px;
	margin-top: 10px;
	font-family: 'Cairo-Bold';
	font-size: 16px;
	cursor: pointer;
	color: #fff;
	width: 250px;
	box-shadow: 0px 4px 4px #0000000a;
	background: #6418c3;
}

.linkButton:hover {
	background: #5811af;
}

.pageImage {
	width: 40;
	border-radius: 50%;
	height: 40;
	object-fit: cover;
	margin-right: 1rem;
}
