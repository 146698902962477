.CustomizedLoader__SM {
	border: 5px solid #dddddd;
	border-radius: 81%;
	border-top: 5px solid #6418c3;
	width: 20px;
	height: 20px;
	animation: spin 2s linear infinite;
	margin-right: 10px;
}

.CustomizedLoader__LG {
	border: 10px solid #dddddd;
	border-radius: 81%;
	border-top: 10px solid #6418c3;
	width: 41px;
	height: 41px;
	animation: spin 2s linear infinite;
}
.CustomizedLoader__VG {
	border: 10px solid #dddddd;
	border-radius: 81%;
	border-top: 10px solid #6418c3;
	width: 100px;
	height: 100px;
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
